// Wait for DOM to be fully loaded
document.addEventListener("DOMContentLoaded", () => {
  let isScrolling = false;

  // Tab switching functionality
  const initializeTabs = () => {
    const sidebarLinks = document.querySelectorAll(".wpcb-lotto-single__sidebar > a");

    sidebarLinks.forEach((link) => {
      link.addEventListener("click", function (e) {
        if (isScrolling) return;
        isScrolling = true;

        const tabId = this.getAttribute("data-tab");
        const tabIcon = this.getAttribute("data-icon");
        const tabTitle = this.textContent;

        // Remove active class from all links
        sidebarLinks.forEach((link) => link.classList.remove("wpcb-lotto-single__sidebar-link-active"));

        // Hide all tab contents
        document.querySelectorAll(".wpcb-lotto-tab__content").forEach((tab) => {
          tab.style.display = "none";
        });

        // Activate current tab
        this.classList.add("wpcb-lotto-single__sidebar-link-active");
        const currentTab = document.getElementById(`wpcb-lotto-tab-${tabId}`);
        currentTab.style.display = "block";

        // Update header
        document.querySelector(".wpcb-lotto-single__header-title").textContent = tabTitle;
        const headerIcon = document.querySelector(".wpcb-lotto-single__header-icon");
        headerIcon.src = tabIcon;
        headerIcon.alt = tabTitle;

        // Smooth scroll to tab content
        const targetPosition = currentTab.offsetTop - 200;
        smoothScroll(targetPosition, 500, () => {
          isScrolling = false;
        });
      });
    });
  };

  // Smooth scroll implementation
  const smoothScroll = (targetPosition, duration, callback) => {
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    const startTime = performance.now();

    const animation = (currentTime) => {
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);

      // Easing function
      const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

      window.scrollTo(0, startPosition + distance * easeInOutQuad(progress));

      if (progress < 1) {
        requestAnimationFrame(animation);
      } else if (callback) {
        callback();
      }
    };

    requestAnimationFrame(animation);
  };

  // Lotto checker functionality
  const LottoThai = {
    numberFormat(value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    checkable() {
      let hash = window.location.hash;
      if (hash.includes("#")) {
        hash = hash.replace("#", "");
        const regex = /([0-9]{6})(,)?/;
        const match = regex.exec(hash);

        if (!match) return false;

        const listHash = hash.split(",");
        return listHash.every((num) => !isNaN(parseInt(num))) ? listHash : false;
      }
      return false;
    },

    check(values, lottoData) {
      return values.filter((value) => value.length === 6).map((value) => this.checkTheNumber(value, lottoData));
    },

    checkTheNumber(value, lottoData) {
      const data = {
        isWin: false,
        number: value,
        prize: false,
        reward: false,
      };

      const d2 = value.slice(4, 6);
      const d3f = value.slice(0, 3);
      const d3l = value.slice(3, 6);

      const setPrizeData = (prizeText, reward) => {
        data.isWin = true;
        data.prize = prizeText;
        data.reward = this.numberFormat(reward);
      };

      Object.entries(lottoData).forEach(([idx, lotto]) => {
        lotto.numbers.forEach((number) => {
          if (value === number) {
            setPrizeData(idx == 11 ? "ถูกรางวัลที่ 1" : `ถูกรางวัลที่ ${idx}`, lotto.reward);
          } else if (d2 === number) {
            setPrizeData("เลขท้าย 2 ตัว", lotto.reward);
          } else if (d3l === number && idx == 6) {
            setPrizeData("เลขท้าย 3 ตัว", lotto.reward);
          } else if (d3f === number && idx == 10) {
            setPrizeData("เลขหน้า 3 ตัว", lotto.reward);
          }
        });
      });

      return data;
    },

    displayResult(values) {
      if (typeof wpcbLotto === "undefined" || typeof wpcbLotto.lottoThaiResult === "undefined") {
        return;
      }

      const lottoData = JSON.parse(wpcbLotto.lottoThaiResult);
      const check = this.check(values, lottoData);

      const displayResult = document.getElementById("wpcbLottoThaiResult");
      if (displayResult && wp.template) {
        const template = wp.template("check-lotto-thai");
        displayResult.innerHTML = template(check);

        const resultPosition = displayResult.offsetTop - 100;
        smoothScroll(resultPosition, 500);
      }
    },

    handleChecking() {
      const checkable = this.checkable();
      if (!checkable) return;

      this.displayResult(checkable);
    },

    handleCheckingSubmit() {
      const form = document.getElementById("wpcbLottoThaiCheckForm");
      if (!form) return;

      form.addEventListener("submit", (e) => {
        e.preventDefault();

        const lottoValues = [];
        const codeInputs = form.querySelectorAll('[name="code[]"]');
        let hash = "";

        codeInputs.forEach((input) => {
          const valueCode = input.value.trim();
          if (valueCode.length === 6) {
            hash += hash === "" ? "#" : ",";
            hash += valueCode;
            lottoValues.push(valueCode);
          }
        });

        if (lottoValues.length > 0) {
          const baseHref = location.origin + location.pathname;
          window.location.href = baseHref + hash;

          this.displayResult(lottoValues);
        } else {
          alert("กรุณาระบุหมายเลขสลากให้ถูกต้อง");
        }
      });
    },
  };

  // Initialize functionality
  initializeTabs();
  LottoThai.handleChecking();
  LottoThai.handleCheckingSubmit();
});
